<template>
    <vx-card title="Emergency Interstorage Stock Movement">
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Inter Storage Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select
                            :filterable="true"
                            :options="documents"
                            v-model="code"
                            label="code"
                            :searchable="true"
                            :clearable="false"
                        />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                </div>
            </div>
        </div>
        <br>
        <div class="vx-col sm:w-1/1 w-full">
            <div style="
                position: absolute;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            ">
                <vs-button class="mr-3 mb-2" @click="handleUnreserve()">Submit</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            code: null,
            documents: [],
        }
    },
    mounted() {
        this.getDocuments()
    },
    methods: {
        getDocuments() {
            this.$http.get("/api/wms/v1/inter-storage/get-all-document")
            .then(resp => {
                if (resp.code == 200) {
                    if (resp.data) {
                        this.documents = resp.data
                    } else {
                        this.documents = []
                    }
                } else {
                    this.documents = []
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleUnreserve() {
            console.log("dapat code => ", this.code.code)

            let params = {
                code: this.code.code,
            }
            this.$http.post("/api/wms/v1/inter-storage/unreserve-document", params)
            .then(resp => {
                if (resp.code == 200) {
                    console.log("unreserved")
                } else {
                    console.log("error unreserved => ", resp.message)
                }
            })
            .catch(error => {
                console.log(error)
            });
            // ...
        },
        handleMovement(code, type) {
            // ...
        }
    }
}
</script>